﻿// CONTROL.CTA

.c-cta {
	@include control;
}

.c-cta__layout {
	@include layout-wrapper;
}

.c-cta--dark {
	.c-cta__body {
		background: $grey--darkest;
	}
	.c-cta__heading,
	.c-cta__text{
		color: $white;
	}
}

.c-cta__body {
	@include radius;
	position: relative;
	padding: $bsu;
	margin: $bsu 0;
	text-align: center;
	background-color: $grey--lightest;

	> *:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}

	@include bp("medium") {
		display: flex;
		padding: ($bsu * 1.5) ($bsu * 2);
		align-items: center;
		text-align: left;
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;

	@include bp("medium") {
		flex: 1 1 auto;
		padding-right: $lsu;
		margin-bottom: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	@include text--heading-3;
	margin-bottom: 0.5em;
	color: $body-color;

	@include bp("large") {
		@include text--heading-2;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $body-color;
}

.c-cta__link {
	@include button("medium");
	flex: 0 0 auto;

	@include bp("medium") {
		white-space: nowrap;
	}
}
