﻿.c-eligibility-form {

}

.c-eligibility-form__layout {
	@include layout-wrapper--narrow
}

.c-eligibility-form__response {
	@include body-text;
	background: $grey--light;
	padding: $bsu;
	margin-top: $lsu;
}

#form-eligible {
	width: 100%;
}