﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
	position: relative;
	padding-left: $bsu;
	&:before {
		content: "";
		display: block;
		width: 4px;
		height: 100%;
		background: $primary-a;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.m-quote__text {
	@include text--regular(20px, 28px);
}

.m-quote__citation {}
