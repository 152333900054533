﻿// theme

$white: #fff;
$black: #231f20;

$grey--lightest: #f9f9f9;
$grey--light: #efefef;
$grey: #d0d0d0;
$grey--dark: #666;
$grey--darkest: #231F20;

$blue: #29ade5;

$red: #D71920;
$red--dark: #780c15;

$brand: $red;
$brand--alt: darken($brand, 10);

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey--light;
$maps-background: #e5e3df;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $brand--alt !default;
$secondary-b: $white !default;

$link: $brand !default;
$link-hover: $brand--alt !default;
