// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes

$base-font-family: "Inter", Arial, Helvetica, sans-serif;
$heading-font-family: "Inter", Times New Roman, Georgia, serif;
$base-font-size: 16px;
$base-line-height: 22px;

$font-weight: ( 'light': 300, 'normal': 400, 'medium': 500, 'semibold': 600, 'bold': 700, 'extrabold': 800, 'black': 900 );

$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;
$weight--extrabold: 800;
$weight--black: 900;


@mixin text($font-size: 16px, $line-height: 1.3, $weight--normal: 400, $letter-spacing: 0px) {
	font-family: $base-font-family;
	font-size: $font-size;
	line-height: $line-height;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: $weight--normal;
}

@mixin text--regular($font-size: 16px, $line-height: 1.3, $letter-spacing: 0px) {
	font-family: $base-font-family;
	font-size: $font-size;
	line-height: $line-height;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: $weight--normal;
}


@mixin text--medium($font-size: 16px, $line-height: 1.3, $letter-spacing: 0px) {
	font-family: $base-font-family;
	font-size: $font-size;
	line-height: $line-height;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: $weight--medium;
}


@mixin text--bold($font-size: 16px, $line-height: 1.3,$letter-spacing: 0px) {
	font-family: $base-font-family;
	font-size: $font-size;
	line-height: $line-height;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: $weight--bold;
}

@mixin text--italic {
	font-style: italic;
}
/*
@mixin text--impact-number {
	@include text(72px, 64px, medium);

	@include bp('large') {
		@include text(90px, 1, medium);
	}
}

@mixin text--home-hero {
	@include text(26px, 28px, semibold);

	@include bp('large') {
		@include text(64px, 60px, semibold);
	}
}

@mixin text--heading-1 {
	@include text(32px, 30px, semibold);

	@include bp('large') {
		@include text(52px, 48px, semibold);
	}
}

@mixin text--heading-2 {
	@include text(26px, 28px, semibold);

	@include bp('large') {
		@include text(36px, 36px, semibold);
	}
}

@mixin text--heading-3 {
	@include text(18px, 24px, semibold);

	@include bp('large') {
		@include text(24px, 28px, semibold);
	}
}

@mixin text--heading-4 {
	@include text(16px, 24px, semibold);

	@include bp('large') {
		@include text(18px, 24px, semibold);
	}
}

@mixin text--heading-5 {
	@include text(14px, 16px, semibold);

	@include bp('large') {
		@include text(16px, 24px, semibold);
	}
}

@mixin text--quote {
	@include text(20px, 24px, medium, null, 1px);

	@include bp('large') {
		@include text(21px, 28px, medium, null, 0.42px);
	}
}


*/

@mixin text--heading-1 {
	@include text--bold(32px, 36px);
}

@mixin text--heading-2 {
	@include text--bold(28px, 32px);
}

@mixin text--heading-3 {
	@include text--bold(24px, 32px);
}

@mixin text--heading-4 {
	@include text--bold(20px, 28px);
}

@mixin text--heading-5 {
	@include text--bold(18px, 24px);
}

@mixin text--body-xsmall($weight: null) {
	@include text(12px, 18px);

	@if $weight {
		font-weight: $weight !important;
	}
}

@mixin text--body-small($weight: null) {
	@include text(14px, 18px);

	@if $weight {
		font-weight: $weight !important;
	}
}

@mixin text--body($weight: null) {
	@include text(16px, 24px);

	@if $weight {
		font-weight: $weight !important;
	}
}

@mixin text--body-large($weight: null) {
	@include text(18px, 24px);

	@if $weight {
		font-weight: $weight !important;
	}
}

@mixin text--caption {
	@include text(14px, 24px);
}

@mixin font-body-text {
	@include text--regular(16px, 24px);
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
