// OBJECT.BTN

// Shared button styles

@mixin button-size($size: "medium", $icon: false) {
	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@if ($size == "large") {
			@include text--body-large($weight--bold);
			padding: 24px $lsu;
			@include radius;
		}
		@else if ($size == "medium") {
			@include text--body($weight--bold);
			padding: ($ssu * 1.5) ($ssu * 2);
			@include radius;
		}
		@else if ($size == "small") {
			@include text--body-small($weight--bold);
			padding: 11px 17px;
			@include radius;
		}
		@else if ($size == "smallest") {
			@include text--body-small($weight--bold);
			padding: 6px 14px;
			@include radius;
		}
		@else {
			@warn "Unknown button size #{$size}.";
		}
	}
}

@mixin button-icon-size($size: "medium") {
	@if ($size == "large") {
		height: 74px;
		width: 74px;
		font-size: 28px;
		line-height: #{74px - (23px * 2)};
		padding: 23px;
	}
	@else if ($size == "medium") {
		height: 54px;
		width: 54px;
		font-size: 24px;
		line-height: #{54px - (15px * 2)};
		padding: 15px;
	}
	@else if ($size == "small") {
		height: 40px;
		width: 40px;
		font-size: 16px;
		line-height: #{40px - (11px * 2)};
		padding: 11px;
	}
	@else if ($size == "smallest") {
		height: 30px;
		width: 30px;
		font-size: 14px;
		line-height: #{30px - (6px * 2)};
		padding: 6px;
	}
}

@mixin button($size: "medium", $icon: false) {
	@include link-transition;
	display: inline-block;

	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@include button-size($size);
	}

	@include radius;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

@mixin button--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

@mixin button--primary {
	background: $primary-a;
	color: $white;
	border: 1px $primary-a solid;

	&:hover,
	&:focus {
		background: $secondary-a;
		border-color: $secondary-a;
	}

	&:disabled {
		cursor: not-allowed;
		background: $grey--light !important;
		border-color: $grey--light !important;
		pointer-events: none;
	}
}

@mixin button--grey {
	background: $grey--light;
	border: 1px $grey--light solid;
	color: $body-color;

	&:hover,
	&:focus {
		background: $primary-a;
		border-color: $primary-a;
		color: $white;
	}

	&:disabled {
		cursor: not-allowed;
		background: $grey--light !important;
		border-color: $grey--light !important;
		pointer-events: none;
	}
}


.o-button {
	@include button('medium');
}

.o-button--large {
	@include button('large');
}

.o-button--small {
	@include button('small');
}

.o-button--xsmall {
	@include button('smallest');
}

.o-button--primary {
	@include button--primary;
}

.o-button--text {
	@include button--text;
}

.o-button--grey {
	@include button--grey;
}
