$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-pdf: unquote('"\\e944"');
$iconf-search: unquote('"\\e919"');
$iconf-enlarge: unquote('"\\e92c"');
$iconf-reduce: unquote('"\\e943"');
$iconf-home: unquote('"\\e918"');
$iconf-arrow-up: unquote('"\\e904"');
$iconf-arrow-down: unquote('"\\e903"');
$iconf-arrow-left: unquote('"\\e905"');
$iconf-arrow-right: unquote('"\\e906"');
$iconf-close: unquote('"\\e907"');
$iconf-minus: unquote('"\\e908"');
$iconf-plus: unquote('"\\e909"');
$iconf-tick: unquote('"\\e90a"');
$iconf-link: unquote('"\\e90d"');
$iconf-external: unquote('"\\e90c"');
$iconf-download: unquote('"\\e90b"');
$iconf-photo: unquote('"\\e90e"');
$iconf-camera: unquote('"\\e90f"');
$iconf-video: unquote('"\\e910"');
$iconf-play: unquote('"\\e911"');
$iconf-email: unquote('"\\e912"');
$iconf-print: unquote('"\\e914"');
$iconf-phone: unquote('"\\e91b"');
$iconf-fax: unquote('"\\e913"');
$iconf-address: unquote('"\\e916"');
$iconf-google-maps: unquote('"\\e915"');
$iconf-calendar: unquote('"\\e917"');
$iconf-share: unquote('"\\e91c"');
$iconf-facebook: unquote('"\\e922"');
$iconf-twitter: unquote('"\\e91f"');
$iconf-youtube: unquote('"\\e924"');
$iconf-flickr: unquote('"\\e920"');
$iconf-pinterest: unquote('"\\e921"');
$iconf-instagram: unquote('"\\e902"');
$iconf-linkedin: unquote('"\\e923"');
$iconf-weibo: unquote('"\\e928"');
$iconf-wechat: unquote('"\\e900"');
$iconf-youku: unquote('"\\e927"');
$iconf-snapchat: unquote('"\\e926"');
$iconf-blogger: unquote('"\\e925"');
$iconf-tumblr: unquote('"\\e91e"');
$iconf-tripadvisor: unquote('"\\e91d"');
$iconf-web: unquote('"\\e91a"');
$iconf-chat: unquote('"\\e017"');
$iconf-filter: unquote('"\\e929"');
$iconf-reload: unquote('"\\e92a"');
$iconf-sort: unquote('"\\e92b"');
$iconf-arrow-right-small: unquote('"\\e901"');
$iconf-arrow-down-small: unquote('"\\e92d"');
$iconf-arrow-up-small: unquote('"\\e92e"');
$iconf-arrow-left-small: unquote('"\\e92f"');
$iconf-arrow-out: unquote('"\\e930"');
$iconf-upload: unquote('"\\e931"');
$iconf-shopping-cart: unquote('"\\e932"');
$iconf-Delete: unquote('"\\e933"');
$iconf-Edit: unquote('"\\e934"');
$iconf-Block: unquote('"\\e935"');
$iconf-settings: unquote('"\\e936"');
$iconf-more: unquote('"\\e937"');
$iconf-menu: unquote('"\\e938"');
$iconf-logout: unquote('"\\e939"');
$iconf-login: unquote('"\\e93a"');
$iconf-view-list: unquote('"\\e93b"');
$iconf-grid-view: unquote('"\\e93c"');
$iconf-language: unquote('"\\e93d"');
$iconf-favorite: unquote('"\\e93e"');
$iconf-Warning: unquote('"\\e93f"');
$iconf-Info: unquote('"\\e940"');
$iconf-Error: unquote('"\\e941"');
$iconf-Help: unquote('"\\e942"');

