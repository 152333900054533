// COMPONENT.FOOTER

@use "sass:math";

$footer-logo-width: 150px;

.c-footer {
	position: relative;
	background-color: $secondary-b;
	margin-top: $hsu;
}

.c-footer__layout {
	@include layout-wrapper;

	@include bp('small') {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.c-footer__primary {
	padding: $bsu 0;
	background-color: $black;
	border-bottom: 2px $grey--dark solid;

	@include bp("medium") {
		display: flex;
	}
}

.c-footer__secondary {
	padding: $bsu 0;
	background-color: $black;
}

.c-footer__logo {
	display: none;
	width: $footer-logo-width;
	//padding: $lsu 0;
	margin: 0 auto;
	text-align: center;

	img {
		width: 150px;
	}

	@include bp("medium") {
		display: block;
		align-self: flex-start;
		margin: 0;
	}
}

.c-footer__signup {
	padding: $bsu 0;
}

.c-footer__graphic {
	display: block;
	max-width: 80%;
	margin-right: 0;
	margin-left: auto;

	@include bp('medium') {
		max-width: 70%;
	}

	@include bp('large') {
		width: 60%;
		max-width: 800px;
	}
}

.c-footer__navigation {
	@include list-reset;
	column-count: 2;
	column-gap: $lsu;
	margin-bottom: $bsu;

	@include bp("medium") {
		flex: 0 0 auto;
		width: calc(50% - #{math.div($footer-logo-width, 2)});
		padding-left: $lsu;
		margin-bottom: 0;
	}

	@include bp("large") {
		width: calc(75% - #{$footer-logo-width});
		padding-left: $hsu;
	}
}

.c-footer__navigation-link {
	@include text--body;
	display: inline-block;
	padding: $tsu 0;
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__social {
	@include bp("medium") {
		flex: 0 0 auto;
		width: calc(50% - #{math.div($footer-logo-width, 2)});
		padding-left: $lsu;
	}

	@include bp("large") {
		width: 25%;
		padding-left: $hsu;
	}
}

.c-footer__social-heading {
	display: block;
	margin-bottom: $ssu;
	color: $white;
}

.c-footer__social-list {
	@include list-reset;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include icon-wrapper;

	@include square(40px);
	@include link-transition;
	border: 1px solid $white;
	font-size: 20px;
	color: $white;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $brand;
		background-color: $white;
	}
}

.c-footer__legal {
	margin-bottom: $bsu;
	text-align: center;

	@include bp("small") {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.c-footer__legal-copyright {
	@include text--body-xsmall;
	display: block;
	margin-bottom: $ssu;
	color: $white;

	@include bp("small") {
		margin: 0;
	}
}

.c-footer__legal-links {
	@include list-reset;
	text-align: center;
	width: 100%;

	@include bp("medium") {
		display: flex;
		flex-wrap: wrap;
	}

	@include bp('large') {
		align-items: center;
		justify-content: center;
	}
}

.c-footer__legal-links-item {
	display: block;
	padding: 0 0 0 $tsu;
	position: relative;
	text-align: left;

	@include bp("medium") {
		flex: 1 1 50%;
	}

	@include bp("large") {
		flex: 0 0 auto;

		& + &:before {
			left: 0;
			content: "";
			height: 20px;
			width: 1px;
			background: $grey--dark;
			margin: 0 $ssu;
			vertical-align: middle;
			display: inline-block;
		}
	}
}

.c-footer__legal-link {
	@include text--body-small;
	color: $white;
	display: inline-block;
	padding: $ssu 0;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include text--body-xsmall;
	display: block;
	text-align: center;
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__back-to-top-wrapper {
	@include layout-wrapper;
	text-align: right;
}

.c-footer__back-to-top {
	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 54px;
	height: 54px;
	font-size: 32px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;
	border-radius: 4px 0 0 4px;

	&::before {
		@include font-icon;
		content: $iconf-arrow-up;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				border: none;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
