/* OBJECT.FAKE-INPUT */

@mixin fake-input {
	// Hide, but still allow access through tabbing
	position: absolute !important;
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	overflow: hidden !important;
	padding: 0 !important;
	height: 1px !important;
	width: 1px !important;
	margin: -1px !important;
}

@mixin fake-input__wrapper {
	position: relative;
}

@mixin fake-input__display {
	position: relative;
	display: block;
	width: 25px;
	height: 25px;
	line-height: 25px;

	border: 1px solid $grey;
	margin-right: $ssu;
	text-align: center;
}
