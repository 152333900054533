// component.page-header

.c-page-header {
	padding: $lsu 0 $lsu*1.5;
	text-align: center;
	color: $white;
	position: relative;
	background: $primary-a;

	&:after {
		display: block;
		position: absolute;
		content: "";
		background: $primary-a url("../../../Content/images/interface/hero-line-graphic.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: right bottom;
		width: 80%;
		height: 100%;
		bottom: 0;
		right: 0;
	}

	@include bp("medium") {
		padding: $lsu*1.5 0 $hsu;
		&:after {
			max-width: 70%;
		}
	}

	@include bp("large") {
		padding: $hsu 0 $hsu*1.5;
		&:after {
			width: 60%;
			max-width: 800px;
		}
	}
}

.c-page-header__layout {
	@include layout-wrapper;
	position: relative;
	z-index: 1;

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-page-header__lede,
.o-layout-header__lede {
	@include text--body-large;
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-page-header__date {
	@include text--body-small;
	margin-bottom: $tsu;
	display: inline-block;
}

.c-page-header__author {
	@include text--body-small;
	margin-bottom: $bsu;
	display: inline-block;
	text-transform: capitalize;


	span {
		padding: 0 $ssu;
	}
}
