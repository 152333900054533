// COMPONENT.COOKIE-CONFIRM

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: none;

	background: $white;
	box-shadow: 0 0 5px 5px rgba($black, 0.1);

	@include bp("large") {
		max-width: 400px;
		left: $bsu;
		bottom: $bsu;
	}
}

.c-cookie-confirm__body {
	display: flex;
	position: relative;
	padding: $bsu;

	flex-direction: column;
	align-items: flex-end;
}

.c-cookie-confirm__message {
	@include body-text;

	width: 100%;
	margin-bottom: $ssu;

	@include font-body-text;

	p:last-child {
		margin-bottom: 0;
	}

	@include bp("medium") {
		flex: 1 1 auto;
		margin: 0;
	}
}

.c-cookie-confirm__button {
	@include button("smallest");

	flex: 0 1 auto;
	margin-top: $ssu;
}
