﻿// CONTROL.DOCUMENT LISTING

.c-document-listing {
	@include control;
}

.c-document-listing__layout {
	@include layout-wrapper--narrow;
}

.c-document-listing__heading {
	@include control__heading;
}

.c-document-listing__list {
}



/*--------------------*/
/* DOCUMENT LIST ITEM */
/*--------------------*/
.o-document-list-item {
	@include link-transition;
	@include radius;
	color: $body-color;
	display: block;
	margin-bottom: $bsu;
	background: $grey--lightest;
	display: flex;
	align-items: center;
	padding: $bsu;

	@include bp('medium') {
		padding: $bsu $lsu;
	}

	&:hover,
	&:focus {
		box-shadow: 0 0 0 1px rgba(84,87,87,0.3),0 9px 21px -20px black;
		/*		.o-document-list-item__button {
			background: $primary-a;
			border-color: $primary-a;
			color: $white;
		}*/
	}
}

.o-document-list-item__icon {
	font-size: 32px;
	margin-right: $bsu;

	@include bp('medium') {
		font-size: 40px;
	}
}

.o-document-list-item__name {
	@include text--body($weight--bold);
}

.o-document-list-item__date {
	@include text--body-xsmall;
	margin: 0;
}

.o-document-list-item__button {
	margin-right: 0;
	margin-left: auto;
	display: inline-flex;
	align-items: center;

	.o-button__icon {
		font-size: 20px;
		margin-right: $ssu;
	}

	@include bp('medium') {
		.o-button__icon {
			margin-right: $bsu;
			font-size: 30px;
		}
	}
}
