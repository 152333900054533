﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	@include layout-wrapper;
	margin-top: $lsu;
	margin-bottom: $lsu;
}

.c-breadcrumb__list {
	@include list-reset;
	@include list-inline;
}

.c-breadcrumb__link {
	@include text--body-xsmall;
}

.c-breadcrumb__current {
	@include text--body-xsmall($weight--bold);
	@include link-plain;
}
