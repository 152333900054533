// COMPONENT.PAGINATION

@use "sass:math";

$pagination-bp: "medium";

.c-pagination {
	display: flex;
	@include text--body-small;
	justify-content: center;
	align-items: center;
	margin-top: $bsu;
	margin-bottom: $bsu;
	text-align: center;

	@include bp("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}
}

.c-pagination__desktop {
	flex-grow: 1;

	@include grid;

	@include bp-below($pagination-bp) {
		display: none;
	}
}

.c-pagination__summary {
	padding-left: $bsu;
	width: percentage(math.div(1, 3));
}

.c-pagination__body {
	padding-left: $bsu;
	text-align: center;
}

.c-pagination__list {
	@include list-reset;
	@include list-inline;
	@include text--body-small($weight--medium);
}

.c-pagination__mobile {
	text-align: center;
	@include bp($pagination-bp) {
		display: none;
	}
}

.c-pagination__current {
	padding: 0 $bsu;

	@include bp($pagination-bp) {
		padding: 0 $lsu;
	}
}

.c-pagination__dropdown {
	padding: $ssu;
	margin: 0 $tsu;
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	&, a {
		@include link-transition;
		@include text--body-small($weight--bold);
		padding: $tsu;
		white-space: nowrap;
		color: $primary-a;
	}

	a {
		display: inline-block;
	}

	&[aria-current]:not([aria-current="false"]) a {
		border: 1px $primary-a solid;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		cursor: default;
		pointer-events: none;
		text-decoration: none;
	}

	&:hover a {
		color: $body-color;
	}
}

.c-pagination__nav-text {
	display: none;

	@include bp("small") {
		display: inline-block;
		vertical-align: text-bottom;
	}
}
