// OBJECT.CONTROL

@mixin control {
	margin-top: var(--control-gap);
	margin-bottom: var(--control-gap);

	@include bp("large") {
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

@mixin control__heading {
	@include text--heading-2;
	@include heading-decoration;
	margin-bottom: $lsu;
}

@mixin control__figcaption {
	@include ui-border("bottom");
	@include text--body;
	padding: $bsu 0;
}
