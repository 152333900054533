﻿// config

@use "sass:math";

// Spacings
// $bsu: Base spacing unit
// $tsu: Tiny spacing unit
// $ssu: Small spacing unit
// $lsu: Large spacing unit
// $hsu: Huge spacing unit
$bsu: 20px;
$tsu: math.div($bsu, 4);
$ssu: math.div($bsu, 2);
$lsu: $bsu * 2;
$hsu: $bsu * 4;

// Breakpoints
// Suffixes used for namespaced helpers
// Pixel values will be output as em units
$breakpoints: (
	"extra-small" (
		"suffix": "xs",
		"start": 0,
		"end": 479px
	),
	"small" (
		"suffix": "s",
		"start": 480px,
		"end": 767px
	),
	"medium" (
		"suffix": "m",
		"start": 768px,
		"end": 1119px
	),
	"large" (
		"suffix": "l",
		"start": 1120px,
		"end": 1400px
	),
	"extra-large" (
		"suffix": "xl",
		"start": 1401px,
		"end": 9000px
	)
);

// Border radius
$radius: 4px;

// Icons
$icomoon-font-path: '/Content/fonts/font-icons/fonts';

// Columns
// By default we will create wholes, halves, thirds, quarters, and fifths
// Predefine this map to override
$columns: (1, 2, 3, 4, 5);

// Z-Index
// 1
$z-page: 1;

// 2
$z-header: 2;
$z-share: 2;
$z-slick-arrows: 2;

// 3
$z-tooltip: 3;

// 4
$z-back-to-top : 4;

// Layout dimensions
:root {
	--scrollbar-width: 17px;
	--page-width-full: calc(100vw - var(--scrollbar-width));
	--page-width: var(--page-width-full);
	--layout-gutter-left: #{$bsu};
	--layout-gutter-right: #{$bsu};
	--control-gap: #{$lsu};

	@include bp('large') {
		--control-gap: #{$hsu};
	}
}

// Max width of layout wrapper which contains content
$layout-wrapper-max-width: 1360px;

// Width of sidebar (sidebar sits alongside main column at 'large' breakpoint)
$layout-aside-width: 280px;
$layout-aside-gutter-width: 60px;
$layout-wrapper-narrow-width: $layout-wrapper-max-width - ($layout-aside-width + $layout-aside-gutter-width);
$layout-wrapper-modal-width: $layout-wrapper-narrow-width;
