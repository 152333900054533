// layout.page

$layout-page-bp: 'large';
$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));
$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});

// minmax fix applied to prevent page blowout: https://css-tricks.com/preventing-a-grid-blowout/

.l-page {
	display: grid;
	grid-template-areas: "header" "main-controls" "footer-controls" "sidebar" "footer";

	@include bp($layout-page-bp) {
		grid-template-columns: minmax(0, 1fr);

		&.l-page--has-sidebar {
			grid-template-columns: minmax(0, 1fr) $layout-page-aside-column-width;
			grid-template-areas: "header header" "main-controls sidebar" "footer-controls footer-controls" "footer footer";
		}
	}
}

.l-page__header {
	grid-area: header;
	margin-bottom: $lsu;
}

.l-page__main-controls {
	grid-area: main-controls;
	min-width: 0;

	.l-page--has-sidebar & {
		@include bp($layout-page-bp) {
			margin-left: $layout-page-gutter;
			--layout-gutter-right: 0;
		}
	}
}

.l-page__footer-controls {
	grid-area: footer-controls;
	padding-top: var(--control-gap);
}

.l-page__sidebar {
	grid-area: sidebar;
	padding: var(--control-gap) var(--layout-gutter-right) 0 var(--layout-gutter-left);

	@include bp($layout-page-bp) {
		padding: 0 calc(var(--layout-gutter-right) + #{$layout-page-gutter}) 0 $layout-aside-gutter-width;
	}
}

.l-page__footer {
	grid-area: footer;
}
