/* OBJECT.LAYOUT */

@mixin layout-wrapper--flush($alignment: "centre", $width: $layout-wrapper-max-width) {
	max-width: $width;

	@if ($alignment == "centre" or $alignment == "center") {
		margin: 0 auto;
	}

	flex-grow: 1;
}

@mixin layout-wrapper($alignment: "center", $width: $layout-wrapper-max-width) {
	@include layout-wrapper--flush($alignment, $width);
	padding: 0 var(--layout-gutter-right) 0 var(--layout-gutter-left);

	> * {
		--layout-gutter-left: 0;
		--layout-gutter-right: 0;
	}
}

@mixin layout-wrapper--narrow($alignment: "centre") {
	@include layout-wrapper($alignment, $layout-wrapper-narrow-width);
}

.o-layout-wrapper {
	@include layout-wrapper;
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-body {
	margin: 0 auto;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;
	padding-top: $bsu * 3;
}

.o-layout-content--has-aside {
	@include bp("large") {
		display: flex;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {
		@include bp("large") {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp("large") {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $hsu;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header--has-cta {
	// See _component.cta-intro.scss
	text-align: left;
	margin-bottom: $bsu;
}

.o-layout-header__subheading {
	@include text--body-large(600);
	display: block;
	margin-bottom: $ssu;
	@include text--bold(18px, 24px);
}

.o-layout-header__heading {
	display: block;
	margin-bottom: $bsu;
}

.o-layout-header__heading,
.o-layout-header__heading-text {
	@include text--heading-1;
	color: $body-color;

	.c-page-header & {
		color: $white;
	}
}

.o-layout-header__heading-text {
	display: inline-block;
	vertical-align: middle;
}

.o-layout-header__highlight {
	display: inline-block;
	vertical-align: middle;
	margin-left: $bsu;

	@include text--body;
	padding: $tsu $ssu;
}

.o-layout-header__lede--full {
	width: auto;
}

.o-layout-header__date {
	@include text--body-xsmall;
	margin-bottom: 5px;
	display: inline-block;
}

.o-layout-header__author {
	@include text--body-xsmall;
	margin-bottom: 2em;
	display: inline-block;
	text-transform: capitalize;

	span {
		padding: 0 10px;
	}
}
